import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles';
import Button from '@mui/material/Button';

const HomePage = () => {
  //Theme for reponsive text
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  return (
    <Box>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-end"
        style={{ marginTop: 80 }}>
        <ThemeProvider theme={theme}>
          <Grid item xs={12} style={{ marginRight: 20 }}>
            <Typography
              variant="h2"
              gutterBottom
              component="div"
              align="right"
              style={{ color: '#314F6F', fontFamily: 'sans-serif' }}>
              Felanmälan
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginRight: 20 }}>
            <Typography
              variant="h5"
              gutterBottom
              component="div"
              align="right"
              style={{ fontFamily: 'sans-serif', marginTop: 40 }}>
              Här kan du anmäla fel i <br />
              lägenheten eller i fastigheten
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginRight: 20 }}>
            <Typography
              variant="subtitle1"
              gutterBottom
              component="div"
              align="right"
              style={{
                color: '#314F6F',
                fontFamily: 'sans-serif',
                fontWeight: 'bold',
                marginTop: 15,
              }}>
              Arbetstid
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginRight: 20 }}>
            <Typography
              variant="subtitle1"
              gutterBottom
              component="div"
              align="right"
              style={{
                color: '#314F6F',
                fontFamily: 'sans-serif',
                fontWeight: 600,
              }}>
              Vardagar 07.00 - 15.30
            </Typography>
          </Grid>
          <Grid
            container
            xs={12}
            sm={6}
            md={5}
            lg={3.5}
            style={{ marginRight: 20 }}>
            <Typography
              variant="subtitle1"
              gutterBottom
              component="div"
              style={{
                color: '#000',
                backgroundColor: '#314f6f0a',
                fontFamily: 'sans-serif',
                fontSize: '0.8rem',
                lineHeight: '140%',
                padding: '10px',
                textAlign: 'right',
              }}>
              Vi ber om överseende med vår handläggningstid och gör vårt bästa
              för att hantera ditt ärende så snabbt som möjligt. Hanteringstiden
              för icke-akuta ärenden är mellan 3–5 arbetsdagar.
              <br />
              <br />
              Om du har frågor kring ett ärende, eller behöver ytterligare
              information, är du välkommen att kontakta oss via e-post på:
              hvo.felanmalan.fastighet@malmo.se
              <br />
              <br />
              För att underlätta vår hantering, vänligen ange ditt ärendenummer
              i mejlet, så återkommer vi till dig så snart vi kan.
            </Typography>
          </Grid>
        </ThemeProvider>
        <Grid item xs={12} style={{ marginRight: 20 }}>
          <Button
            variant="contained"
            href="/create/report"
            style={{
              backgroundColor: '#314F6F',
              fontFamily: 'sans-serif',
              marginTop: 10,
            }}>
            Skapa en ny Felanmälan
          </Button>
        </Grid>
        <Grid item xs={12} style={{ marginRight: 20 }}>
          <Button
            variant="outlined"
            href="/view/reports"
            style={{ marginTop: 20 }}>
            Se tidigare felanmälningar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomePage;
